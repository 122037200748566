// import { gsap, Elastic } from "gsap";
// var $hoverables = document.querySelectorAll(".hoverable");
// for (let i = 0; i < $hoverables.length; i++) {
//   $hoverables[i].addEventListener("mouseenter", onMouseHover);
//   $hoverables[i].addEventListener("mouseleave", onMouseHoverOut);
// }

// function onMouseHover() {
//   gsap.to(".cursor", {
//     width: 70,
//     height: 70,
//     duration: 1,
//     ease: Elastic.easeOut,
//   });
// }
// function onMouseHoverOut() {
//   gsap.to(".cursor", {
//     width: 30,
//     height: 30,
//     duration: 1,
//     ease: Elastic.easeOut,
//   });
// }
